import PropTypes from 'prop-types';
import React from 'react';
import './ConfirmationModal.css';
import Modal from 'react-modal';

const ConfirmationModal = ({
    cancelButtonText,
    confirmButtonText,
    content,
    isOpen,
    onCancel,
    onClose,
    onConfirm,
    title
}) => {
    return (
        <Modal
            className="payment-modal"
            contentLabel="Select Styles"
            isOpen={isOpen}
            onRequestClose={onClose}
        >
            <div className="modal">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2>{title}</h2>
                            <button className="close-button" onClick={onClose}>
                                ×
                            </button>
                        </div>
                        <p>{content}</p>
                        <div className="modal-buttons">
                            <button onClick={onConfirm}>
                                {confirmButtonText}
                            </button>
                            <button onClick={onCancel}>
                                {cancelButtonText}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

ConfirmationModal.propTypes = {
    cancelButtonText: PropTypes.string,
    confirmButtonText: PropTypes.string,
    content: PropTypes.string.isRequired,
    isOpen: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired
};

ConfirmationModal.defaultProps = {
    isOpen: false
};

export default ConfirmationModal;
